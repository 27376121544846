import React from 'react';
import {track, Event} from '../tracking';
import {MeglerprofilButton} from "./MeglerProfilButton";

type ProfileProps = {
    adId: number,
    imageUrl?: string;
    agentProfileUrl?: string;
    name: string;
    phones: Readonly<{ phone: string; phoneLink: string }[]>;
    title?: string;
    orgName: string;
    orgId: number;
    objectPosition: 'object-right' | 'object-top';
    trackingSubvertical?: string;
};

function Profile(props: ProfileProps) {
    const {adId, imageUrl, agentProfileUrl, name, phones, title, orgName, orgId, trackingSubvertical} =
        props;
    return (
        <section id="profile" className="mb-16">
            <h3>Megler for dette salget</h3>
            <p style={{marginBottom: '24px'}}>
                Jeg svarer gjerne på spørsmål om eiendommen og området.
            </p>
            <div className="flex items-center">
                {imageUrl && (
                    <img
                        style={{
                            width: '88px',
                            height: '88px',
                            transform: 'translateZ(0)',
                        }}
                        className={`mr-16 object-cover rounded-full ${props.objectPosition}`}
                        src={imageUrl}
                        alt="Meglerbilde"
                    />
                )}
                <div>
                    <h4 className="mb-0">{name}</h4>
                    {title && <p className="mt-4 text-12">{title}</p>}
                    {phones.length !== 0 && (
                        <div className="mt-16 flex flex-row flex-wrap gap-x-24 gap-y-8">
                            {phones.map((phone, idx) => (
                                <a
                                    key={`sold-state-phone-link-${idx}`}
                                    href={phone.phoneLink}
                                    onClick={track({
                                        eventType: Event.PhoneNo,
                                        orgName,
                                        trackingSubvertical,
                                    })}
                                >
                                    {phone.phone}
                                </a>
                            ))}
                        </div>
                    )}
                    {agentProfileUrl && (
                        <MeglerprofilButton
                            url={agentProfileUrl}
                            adId={adId}
                            orgId={orgId}
                            className={`mt-8 flex gap-16 flex-row pb-0 center h-32`}
                        />)
                    }
                </div>
            </div>
        </section>
    );
}

export default Profile;
